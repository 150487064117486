.dash {
  margin: 0px;
  padding: 0px;
  background-color: #1b203d;

  font-family: system-ui;
  padding-bottom: 20px;
}
.clearfix {
  clear: both;
}
.logo {
  margin: 0px;
  margin-left: 28px;
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
}
.logo span {
  color: #f7403b;
}
.sidenav {
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #272c4a;
  overflow-y: scroll;
  transition: 0.5s;
  padding-top: 30px;
}
.sidenav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.sidenav::-webkit-scrollbar {
  width: 9px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.sidenav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f7403b;
}

.sidenav a {
  padding: 15px 8px 15px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
  background-color: #1b203d;
}
.sidenav a.active {
  color: #f1f1f1;
  background-color: #1b203d;
}
.sidenav {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}
#main {
  transition: margin-left 0.5s;
  padding: 16px;
  margin-left: 300px;
  min-height: 100vh;
}
.dash_head {
  padding: 20px;
  display: flex;
  border-bottom: 1px solid #fff;
}
.logout_button button {
  border: none;
  padding: 5px 10px;
  position: absolute;
  right: 5%;
}
.col-div-3 {
  width: 25%;
  float: left;
}
.box {
  width: 85%;
  height: 100px;
  background-color: #272c4a;
  margin-left: 10px;
  padding: 10px;
}
.box p {
  font-size: 35px;
  color: white;
  font-weight: bold;
  line-height: 30px;
  padding-left: 10px;
  margin-top: 20px;
  display: inline-block;
}
.box p span {
  font-size: 20px;
  font-weight: 400;
  color: #818181;
}
.filter {
  display: flex;
  justify-content: space-between;
}
.filter .filt {
  width: 80%;
}

.tables {
  width: 90%;
  margin: auto;
}
.tables table {
  width: 100%;
}
.tables table,
.tables th,
.tables td {
  border: 1px solid #fff;
  color: #fff;
  padding: 10px;
}

.add button {
  border: none;
  font-size: 17px;
  padding: 8px 30px;
  border-radius: 10px;
  background-color: #f7403b;
  color: #fff;
  font-weight: bold;
}

.inner_form {
  background-color: #818181;
  min-height: 100vh;
}
.create_form {
  background-color: #fff;
}
.create_form label {
  width: 100%;
  margin: 10px 0 5px 0;
  font-weight: bold;
}
.create_form input,
.create_form textarea,
.create_form select {
  width: 100%;
  padding: 10px;
}

.create_form button {
  border: none;
  background-color: #1b203d;
  color: #fff;
  padding: 8px 20px;
  font-size: 18px;
}
.dash span {
  cursor: pointer;
}

/* img */

.image_perv {
  position: relative;
}
.image_perv .close_icon {
  cursor: pointer;
  margin-top: -280px;
  color: red;
}
