.inner_head {
  background-color: rgb(3, 48, 93);
  position: relative;
}
.inner_head .overlay {
  height: 150px;
  background: url("../../public/images/about.jpg") no-repeat;
  background-size: cover;
  opacity: 0.2;
}
.inner_head p {
  color: #fff;
  font-size: 20px;
  padding: 20px;
  position: absolute;
  top: 25%;
  left: 6%;
}
.about_page_content h3 span {
  height: 3px;
  width: 60px;
  background-color: rgb(5 77 151);
  display: block;
}
.about_page_content h3 {
  font-size: 22px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 10px;
}
.about_page_content p,
.about_page_content li {
  font-size: 17px;
  text-align: justify;
}
.about_page_content img {
  border: 4px solid rgb(5 77 151);
}

.ant-tabs-nav-wrap {
  background-color: rgb(3, 48, 93);
}
.ant-tabs .ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 20px;
  font-size: 18px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  color: #fff;
}
.ant-tabs .ant-tabs-tab:hover {
  color: #fff;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  text-shadow: 0 0 0.25px currentcolor;
}
.about_page_content li {
  padding: 5px 0px;
}
.about_page_content li span {
  color: rgb(5 77 151);
  font-size: 20px;
  font-weight: 550;
}
.core p {
  padding-top: 20px;
}
.core p span {
  font-weight: bold;
}
.mission_vision_goals h4 {
  padding-top: 20px;
}
.inner_course h3 {
  padding-top: 20px;
  font-size: 20px;
}

/* new card */

.date__box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ccc;
  border: 4px solid;
  font-weight: bold;
  padding: 5px 10px;
}
.date__box .date__day {
  font-size: 22px;
}
.blog-card {
  padding: 30px;
  position: relative;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.blog-card .date__box {
  opacity: 0;
  transform: scale(0.5);
  transition: 300ms ease-in-out;
}
.blog-card .blog-card__background,
.blog-card .card__background--layer {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.blog-card .blog-card__background {
  background: white;
}
.blog-card .card__background--wrapper {
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
  position: relative;
  overflow: hidden;
}
.blog-card .card__background--main {
  height: 100%;
  position: relative;
  transition: 300ms ease-in-out;
  background-repeat: no-repeat;
  background-size: cover;
}
.blog-card .card__background--layer {
  z-index: 0;
  opacity: 0;
  background: rgba(51, 51, 51, 0.9);
  transition: 300ms ease-in-out;
}
.blog-card .blog-card__head {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-card .blog-card__info {
  z-index: 10;
  background: white;
  padding: 20px 15px;
}
.blog-card .blog-card__info h5 {
  transition: 300ms ease-in-out;
}
.blog-card:hover .date__box {
  opacity: 1;
  transform: scale(1);
}
.blog-card:hover .card__background--main {
  transform: scale(1.2) rotate(5deg);
}
.blog-card:hover .card__background--layer {
  opacity: 1;
}
.blog-card:hover .blog-card__info h5 {
  color: rgb(3, 48, 93);
}

.blog-card .button {
  padding-top: 15px;
  position: relative;
  right: 0px;
}
.blog-card button {
  background: rgb(5 77 151);
  color: #fff;
  border: none;
  font-size: 17px;
  font-weight: 550;
  padding: 11px 15px;
  position: relative;
  left: 0;
  margin-top: -5px;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
}

.blog-card button::after {
  content: "";
  background: #8a1204;
  position: absolute;
  left: 0;
  z-index: -1;
  padding: 0.85em 0.75em;
  display: block;
}
.blog-card button[class^="slide"]::after {
  transition: all 0.35s;
}
.blog-card button[class^="slide"]:hover::after {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.35s;
}
.blog-card button.slide_from_left::after {
  top: 0;
  bottom: 0;
  left: -100%;
  right: 100%;
}

.contact-head h3 {
  font-size: 23px;
  font-weight: bold;
  color: #8a1204;
  font-family: serif;
}
.contact-head h3::after {
  content: " ";
  width: 45px;
  display: block;
  border-bottom: 3px solid #8a1204;
}
.cont {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}
.cont input,
.cont select {
  border: none;
  background-color: #f2f2f2;
  width: 90%;
  height: 50px;
  padding-left: 10px;
  outline: none;
  font-size: 16px;
}
.cont select {
  width: 100%;
}

.cont textarea {
  border: none;
  background-color: #f2f2f2;
  width: 90%;
  outline: none;
  font-size: 16px;
}
.cdetails {
  background-color: #8a1204;
}
.contact-deatils h5 {
  color: #fff;
}
.contact-deatils .cicon {
  font-size: 30px;
  margin-right: 8px;
}
.contact-deatils h5 span {
  font-size: 20px !important;
}
.contact-deatils p {
  color: #fff;
  font-weight: 550;
}
.contact-btn button {
  border: none;
  padding: 10px 20px;
  background-color: #8a1204;
  color: #fff;
}

/* gallery card */
.gallery_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
}
@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 62vh;
  --card-width: calc(var(--card-height) / 1.3);
}
.gallery_card img {
  border: none;
}
.gallery_card {
  background: #fff;
  width: var(--card-width);
  height: var(--card-height);
  /* padding: 1px; */
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  cursor: pointer;
}

.gallery_card::before {
  content: "";
  width: 103%;
  height: 102%;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate),
    #5ddcff,
    #3c67e3 43%,
    #4e00c2
  );
  position: absolute;
  z-index: 0;
  top: -0.9%;
  left: -1.4%;
  animation: spin 3s linear infinite;
}
.gallery_card .card_content {
  background-color: #fff;
  color: rgb(3, 48, 93);
  z-index: 2;
  position: relative;
  padding: 20px;
  width: 95%;
  top: -20px;
  margin: auto;
  border-radius: 10px;
}
.gallery_card h5 {
  text-transform: uppercase;
}
.gallery_card p {
  font-size: 17px;
}
.gallery_card p span {
  font-weight: 550;
  font-size: 15px;
}
.gallery_card button {
  border: none;
  background-color: rgb(5 77 151);
  border-radius: 10px;
  padding: 8px 15px;
  color: #fff;
}
@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}

/* details page  */

.news_events .description img {
  width: 100% !important;
}
.latest_news {
  background-color: #ccc;
  margin: 0 50px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.latest_news .latest_head {
  background-color: #8a1204;
  padding: 10px 20px;
  color: #fff;
}

.latest_news p {
  padding: 0px 20px 15px 20px;
  border-bottom: 1px dashed #8a1204;
  font-size: 17px;
  text-transform: capitalize;
}

/* parser */
.faculty_details h3,
.faculty_details h2,
.faculty_details h4 {
  padding: 4px 0;
}
.faculty_details h4 {
  font-size: 19px;
}

.parse_content p {
  padding-top: 5px !important;
}

.about_page_content .chairman {
  width: 100% !important;
}
