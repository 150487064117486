.login_page {
  background-color: #000;
  position: relative;
}
.login_page .overlay {
  height: 100vh;
  background: url("../../../public/images/about.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
}

.contact-form {
  width: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  bottom: 0;
  margin: 40px auto 0px auto;
}

.contactForm {
  padding: 20px 40px 5px 40px;
  background: #fff;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.contactForm h2 {
  font-size: 30px;
  color: rgb(2, 74, 137);
  font-weight: 500;
}
.contactForm .inputBox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.contactForm .inputBox input,
.contactForm .inputBox textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contactForm .inputBox input[type="submit"] {
  width: 100px;
  background: rgb(2, 74, 137);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
}
@media screen and (max-width: 940px) {
  .contact-section {
    width: 90%;
  }
  .contact-section {
    display: flex;
    flex-direction: column;
  }
  .contact-details,
  .contact-form {
    width: 90%;
    margin: auto;
  }
  .contactForm {
    margin-top: 30px;
  }
}
