.header_first {
  background-color: #8a1204;
  color: #fff;
  padding: 8px 0px;
  height: 46px;
  font-family: "Ysabeau Infant", sans-serif;
  /* font-family: "Mulish", sans-serif; */
}
.header_first .navs {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.header_first ul {
  display: flex;
  flex-wrap: wrap;
}
.header_first ul li {
  list-style: none;
  font-size: 17px;
  padding: 2px 15px;
  cursor: pointer;
  font-weight: 600;
}
.header_first .link_border {
  border-right: 1px solid #d18383;
}
.plus-2 {
  background-color: #fff;
  color: #8a1204;
  border-radius: 2px;
  animation: beat 0.5s infinite alternate;
}
@keyframes beat {
  to {
    transform: scale(1.05);
  }
}
/* second header styling start */
.second_header {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 10px;
  height: 100px;
  font-family: "Chivo", sans-serif;
  background-color: #fff;
  z-index: 1000;
}
.second_header .navs {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.second_header img {
  height: 70px;
}
.second_header .logo {
  padding: 0px 10px 0px 10px;
}
.navbars ul {
  display: flex;
}
.navbars ul li.nav_link {
  list-style: none;
  padding: 8px 5px;
  margin-top: 14px !important;
  margin: 0px 7px;
  font-weight: 550;
  text-transform: uppercase;
  cursor: pointer;
}

.navbars ul li.nav_link::after {
  display: block;
  content: "";
  border-bottom: 2px solid #8a1204;
  margin: 5px 0px;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navbars ul li.nav_link:hover::after {
  transform: scaleX(1);
}
.navbars ul li.nav_link::after {
  transform-origin: 100% 50%;
}
.navbars ul li.nav_link::after {
  transform-origin: 0% 50%;
}
.second_header .button {
  padding-top: 15px;
  position: relative;
  right: 0px;
}
.second_header button {
  background: rgb(5 77 151);
  color: #fff;
  border: none;
  font-size: 17px;
  font-weight: 550;
  padding: 11px 15px;
  position: relative;
  left: 0;
  margin-top: -5px;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
}

.second_header button::after {
  content: "";
  background: #8a1204;
  position: absolute;
  left: 0;
  z-index: -1;
  padding: 0.85em 0.75em;
  display: block;
}
.second_header button[class^="slide"]::after {
  transition: all 0.35s;
}
.second_header button[class^="slide"]:hover::after {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.35s;
}
.second_header button.slide_from_left::after {
  top: 0;
  bottom: 0;
  left: -100%;
  right: 100%;
}
.nav_link {
  position: relative;
}
.dropdown {
  display: none !important;
  position: absolute !important ;
  background-color: #8a1204;
  left: -25px;
  top: 40px;
  z-index: 1000;
}

.dropdown li {
  color: #fff;
  padding: 10px 30px;
  border-bottom: none;
  list-style: none;
  width: 180px;
  margin-left: -33px;
  position: relative;
  overflow: hidden;
}

.dropdown li::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgb(5, 77, 151);
  z-index: -1;
  transition: left 0.3s ease;
}

.dropdown li:hover::before {
  left: 0;
}
.nav_link:hover .dropdown {
  display: block !important;
}
.open_menu {
  display: none;
}
.menu_icon {
  font-size: 50px;
  color: #8a1204;
  margin-top: 10px;
}
.anticon svg {
  display: inline-block;
  font-size: 27px;
  color: #fff;
}
.drawer {
  list-style: none;
  margin-left: -22px;
}
.drawer li {
  font-size: 18px;
  padding: 7px 0;
  color: #fff;
}
.drawer ul {
  list-style: none;
}
.drawer ul li {
  padding: 5px !important;
  margin-left: -10px;
}
.ant-drawer-header {
  background-color: #8a1204;
  position: relative;
}
.ant-drawer-body {
  background-color: rgb(5 77 151);
}
.drawer button {
  background: #fff;
  color: #8a1204;
  border: none;
  font-size: 17px;
  font-weight: 550;
  padding: 6px 12px;
  position: absolute;
  right: 20px;
  bottom: 7px;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
}
/* hero section */
.image {
  /* position: relative; */
  background-color: #200501;
  border-top: 3px solid rgb(5 77 151);
  font-family: "Chivo", sans-serif;
}
.image img {
  width: 100%;
  opacity: 1;
  z-index: 0;
  opacity: 0.5;
}
.carousel-caption {
  top: 60px !important;
}
.carousel-control-next,
.carousel-control-prev {
  display: none;
}
/* .image .content {
  position: absolute;
  z-index: 2;
  top: 11%;
  color: #fff;
} */
.image .title {
  font-size: 40px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 20px 0px;
}
.image .text {
  width: 100%;
  margin: auto;
  font-size: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  line-height: 30px;
  padding: 30px 0px;
}
.image h5 {
  font-size: 22px;
  padding-top: 20px;
  font-weight: 550;
}
.image h5 span {
  border-bottom: 2px solid rgb(182, 212, 241);
  padding-bottom: 8px;
  font-variant: small-caps;
}
.down .icon {
  font-size: 50px;
  margin-top: 100px;
}

.carousel-indicators {
  bottom: 260px !important;
}
.carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}
.carousel-caption {
  bottom: 410px !important;
}
.mbl_plus {
  display: none;
}

@media screen and (max-width: 1320px) {
  .header_first .navs {
    width: 95%;
  }
  .second_header .navs {
    width: 100%;
    padding: 10px 0;
  }
}

@media screen and (max-width: 1255px) {
  .left_nav,
  .right_nav,
  .header_button {
    display: none;
  }
  .open_menu {
    display: block;
  }
}

@media screen and (max-width: 1100px) {
  .header_first .navs {
    width: 100%;
  }
  .college_members {
    display: none;
  }
  .carousel-indicators {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .carousel-caption p {
    display: none;
  }
  .carousel-caption .down {
    display: none;
  }
  .carousel-caption {
    top: 20px !important;
  }
  .mbl_plus {
    display: block;
  }
  .college_contact ul {
    margin-left: -35px !important;
  }
}
@media screen and (max-width: 550px) {
  .header_first {
    height: 80px;
  }
  .header_first ul {
    margin-left: -25px;
  }
  .second_header .logo {
    padding: 0px 0px 0px 0px;
  }
  .second_header {
    padding: 5px 0px;
  }
  .carousel-caption h5 span {
    border: none;
    font-size: 16px !important;
  }
  .carousel-caption span {
    font-size: 18px !important;
  }
  .carousel img {
    height: 300px;
  }
}
