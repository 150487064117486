@import url("https://fonts.googleapis.com/css2?family=Chivo&family=Mulish:wght@200&family=Ysabeau+Infant:wght@200&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
}

/* not found */
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #343a40;
}

.not-found h1 {
  font-size: 6rem;
  margin-bottom: 1rem;
}

.not-found p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.not-found a {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.not-found a:hover {
  text-decoration: underline;
}
/* chat */
.chat-table-container {
  height: 100vh;
  overflow-y: scroll;
}
.chat-table-container button {
  background-color: #8a1204;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  font-size: 18px;
  border: none;
  padding: 5px 15px;
}
.chat-table-container table th,
.chat-table-container table td {
  border: 1px solid #8a1204;
  padding: 5px;
}
