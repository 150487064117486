.footer {
  background-color: #ffffff;
  color: rgb(5 77 151);
  padding: 50px 50px 10px 50px;
  text-align: center;
}
.line {
  width: 300px;
  height: 1px;
  background-color: #8a1204;
  margin: 40px auto;
}
.connect .icons {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.flink {
  text-decoration: none;
  color: rgb(5 77 151);
}
.connect .icons .icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: rgb(5 77 151);
  color: #fff;
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
}

footer {
  background-color: #ccc;
  padding: 14px;
  text-align: center;
  color: rgb(5 77 151);
  font-size: 17px;
}

@media screen and (max-width: 800px) {
  .line {
    width: 200px;
  }
}
@media screen and (max-width: 500px) {
  .footer {
    padding: 50px 10px 10px 10px;
  }
  .connect .icons {
    display: flex;
    justify-content: space-evenly;
  }
}
